<template>
  <v-app>
    <!-- 헤더 -->
    <Header />

    <!-- 네비게이션 -->
    <Nav />

    <!-- 메인 -->
    <v-main>
      <router-view />
    </v-main>

    <!-- 테스터 -->
    <!-- <OMB /> -->

    <!-- 맨 위로 -->
    <ToTop />

    <!-- 알럿 -->
    <Alert />

    <!-- 로딩 -->
    <Loading v-if="isLoading" />
  </v-app>
</template>

<script>
export default {
  components: {
    Header: () => import('@/components/layout/Header'),
    Nav: () => import('@/components/layout/Nav'),
    // OMB: () => import('@/components/tester/OMB'),
    ToTop: () => import('@/components/common/ToTop'),
    Alert: () => import('@/components/common/Alert'),
    Loading: () => import('@/components/common/Loading')
  },
  computed: {
    isLoading() {
      return this.$store.state.layout.isLoading
    }
  },
  created() {
    this.initLoading()
  }
}
</script>

<style lang="scss">
@import '@/styles/index.scss';

#app {
  font-family: $font-family;
  user-select: none;

  main {
    // background-color: $color-bg-gray;
  }
}
</style>
